import { types } from "../../reducers/types";
import axios from "axios";
import { store } from "../../App";
import { URL } from "../../api/tklEnvironments";

export const applicationInitialState = () => {
  return (dispatch) => {
    dispatch({ type: types.INITIAL_STATE });
  };
};

export const createcourseFormData = (payload) => {
  return (dispatch) => {
    dispatch({ type: types.CREATE_COURSE_FORM_DATA, payload });
  };
};
export const createcourseFormDatacourseObjective = (payload) => {
  return (dispatch) => {
    dispatch({
      type: types.CREATE_COURSE_FORM_DATA_OBJECTIVE,
      payload,
    });
  };
};

export const getProgramDetails = () => {
  return (dispatch) => {
    axios
      .get(
        `${URL}/program?isPaginated=${0}&searchKey=status&searchExactValue=active`
      )
      .then(function (response) {
        if (response.data) {
          dispatch({
            type: types.GET_PROGRAM_DETAILS,
            payload: response.data,
          });
        }
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};

export const handingImageUpload = (payload, isProfileData, btnClick) => {
  return (dispatch) => {
    let initialTime = performance.now();
    dispatch({ type: types.SHOW_LOADER });
    axios
      .post(`${URL}/upload-media`, payload.formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        if (response.data) {
          let imageSlug = response.data?.response?.media[0];
          if (payload.type === "AVATAR") {
            if (isProfileData?.reUseDisatch) {
              dispatch({
                type: isProfileData?.dispatchType,
                label: isProfileData?.label,
                payload: imageSlug,
              });

              if (btnClick?.current) {
                btnClick?.current?.click();
              }
            } else {
              dispatch({
                type: types.AVATAR_IMAGE_UPLOAD_ADD,
                payload: imageSlug,
              });
            }
          } else {
            if (isProfileData?.reUseDisatch) {
              dispatch({
                type: isProfileData?.dispatchType,
                label: isProfileData?.label,
                payload: imageSlug,
              });

              if (btnClick?.current) {
                btnClick?.current?.click();
              }
            } else {
              dispatch({
                type: types.BANNER_IMAGE_UPLOAD_ADD,
                payload: imageSlug,
              });
            }
          }
        }
      })
      .catch(function (error) {
        dispatch({
          type: types.API_ERROR,
          payload: "Image not uploaded. please try again ",
        });
      });
    let finalTime = performance.now();
    if (finalTime - initialTime >= 600) {
      dispatch({ type: types.HIDE_LOADER });
    } else {
      setTimeout(() => {
        dispatch({ type: types.HIDE_LOADER });
      }, 600 - (finalTime - initialTime));
    }
  };
};
export const createcourseFormProgramsAdd = (payload) => {
  //  //console.log('p...payload', payload)
  return (dispatch) => {
    dispatch({
      type: types.CREATE_COURSE_FORM_PROGRAMS_ADD,
      payload,
    });
  };
};
export const createcourseFormProgramsDelete = (payload) => {
  //   //console.log('p...payload', payload)
  return (dispatch) => {
    dispatch({
      type: types.CREATE_COURSE_FORM_PROGRAMS_DELETE,
      payload,
    });
  };
};
export const getAuthorDetails = () => {
  return (dispatch) => {
    axios
      .get(
        `${URL}/author?isPaginated=${0}&searchKey=status&searchExactValue=active&sortBy=${"name"}&orderBy=${"ASC"}`
      )
      .then(function (response) {
        if (response.data) {
          dispatch({
            type: types.GET_ALL_AUTHORS,
            payload: response.data,
          });
        }
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};

export const addAuthors = (payload) => {
  return (dispatch) => {
    dispatch({ type: types.ADD_AUTHORS, payload });
  };
};
export const deleteAuthors = (payload) => {
  return (dispatch) => {
    dispatch({ type: types.DELETE_AUTHORS, payload });
  };
};

export const getGradeLevelDetails = () => {
  return (dispatch) => {
    axios
      .get(
        `${URL}/grade-level?isPaginated=${0}&searchKey=status&searchExactValue=active&sortBy=${"index"}&orderBy=${"ASC"}`
      )
      .then(function (response) {
        if (response.data) {
          dispatch({
            type: types.GET_ALL_GRADELEVELS,
            payload: response.data,
          });
        }
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};

export const addgradelevel = (payload) => {
  return (dispatch) => {
    dispatch({ type: types.ADD_GRADELEVEL, payload });
  };
};
export const deleteGradeLevel = (payload) => {
  return (dispatch) => {
    dispatch({ type: types.DELETE_GRADELEVEL, payload });
  };
};

export const getContentAreaDetails = () => {
  return (dispatch) => {
    axios
      .get(
        `${URL}/content-area?isPaginated=${0}&searchKey=status&searchExactValue=active&sortBy=${"name"}&orderBy=${"ASC"}`
      )
      .then(function (response) {
        if (response.data) {
          let responseData = [];
          let index = response?.data?.findIndex(
            (item) => item.slug === "others"
          );
          let data = response?.data?.splice(index, 1);
          responseData = response?.data;
          responseData.push(data[0]);
          dispatch({
            type: types.GET_ALL_CONTENTAREA,
            payload: responseData,
          });
        }
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};

export const addContentArea = (payload) => {
  return (dispatch) => {
    dispatch({ type: types.ADD_CONTENTAREA, payload });
  };
};
export const deleteContentArea = (payload) => {
  return (dispatch) => {
    dispatch({ type: types.DELETE_CONTENTAREA, payload });
  };
};

export const getTagsDetails = () => {
  return (dispatch) => {
    axios
      .get(
        `${URL}/tag?isPaginated=${0}&searchKey=status&searchExactValue=active&sortBy=${"name"}&orderBy=${"ASC"}`
      )
      .then(function (response) {
        if (response.data) {
          dispatch({
            type: types.GET_ALL_TAGS,
            payload: response.data,
          });
        }
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};

export const addTags = (payload) => {
  return (dispatch) => {
    dispatch({ type: types.ADD_TAGS, payload });
  };
};
export const deleteTags = (payload) => {
  return (dispatch) => {
    dispatch({ type: types.DELETE_TAGS, payload });
  };
};

export const getFrameworkDetails = () => {
  return (dispatch) => {
    axios
      .get(
        `${URL}/framework-domain?isPaginated=${0}&searchKey=status&searchExactValue=active&sortBy=${"index"}&orderBy=${"ASC"}`
      )
      .then(function (response) {
        if (response.data) {
          dispatch({
            type: types.GET_ALL_FRAMEWORKS,
            payload: response.data,
          });
        }
      })
      .catch(function (error) {
        console.error("..error", error);
      });
    // dispatch({ type: types.GET_ALL_FRAMEWORKS, payload: domains })
  };
};

export const addFrameworks = (payload) => {
  return (dispatch) => {
    dispatch({ type: types.ADD_FRAMEWORKS, payload });
  };
};
export const deleteFrameworks = (payload) => {
  return (dispatch) => {
    dispatch({ type: types.DELETE_FRAMEWORKS, payload });
  };
};

export const addDataToSubDomainFramework = (payload) => {
  return (dispatch) => {
    dispatch({ type: types.ADD_DATA_TO_SUBDOMAIN, payload });
  };
};

// export const removeDataFromSubDomainFramework = (startIndex, len) => {
//   return (dispatch) => {
//     dispatch({
//       type: types.REMOVE_DATA_FROM_SUBDOMAIN,
//       startIndex: startIndex,
//       len: len,
//     });
//   };
// };
export const removeDataFromSubDomainFramework = (
  startIndex,
  len,
  subDomains
) => {
  return (dispatch) => {
    dispatch({
      type: types.REMOVE_DATA_FROM_SUBDOMAIN,
      startIndex: startIndex,
      len: len,
      subDomains: subDomains,
    });
  };
};
export const deletesubDomainComponentFromDomain = (payload) => {
  return (dispatch) => {
    dispatch({ type: types.DELETE_COMPONENT_DOMAIN_FROM_DOMAIN, payload });
  };
};

export const addFrameworkSubDomain = (payload) => {
  return (dispatch) => {
    dispatch({ type: types.ADD_FRAMEWORKS_SUB_DOMAIN, payload });
  };
};
export const deleteFrameworkSubDomain = (payload) => {
  return (dispatch) => {
    dispatch({ type: types.DELETE_FRAMEWORKS_SUB_DOMAIN, payload });
  };
};

export const checkedStatusChange = (payload) => {
  return (dispatch) => {
    dispatch({ type: types.VALIDATION_CHECK, payload });
  };
};

export const naviagteToCoursePath = () => {};

export const getDataSaveForDraft = () => {
  return (dispatch) => {
    axios.get(`${URL}/savedraft`).then(function (response) {
      // dispatch({type:types.GET_DATA_FOR_SAVE_DRAFT,response.data})
    });
  };
};

export const CreateCoursePostData = (props, boolcondition) => {
  //alert("..create");
  return (dispatch) => {
    // let initialTime = performance.now();
    // dispatch({ type: types.SHOW_LOADER });

    dispatch({ type: types.CHECK_COURSE_NAME, payload: false });
    dispatch({ type: types.NAVIGATE_TO_COURSE_PATH_EDIT, payload: true });
    let appData = {};
    let applicationdata = store && store.getState().coursehome.application;
    ////console.log("....applicationdata", applicationdata);
    appData["name"] = applicationdata.courseName;
    appData["objective"] = applicationdata.courseObjective;
    appData["description"] = applicationdata.courseDescription;
    appData["url"] = applicationdata.url;
    appData["slug"] = applicationdata.slug;
    appData["avatarSlug"] = applicationdata?.avatarImage?.slug || "";
    appData["bannerSlug"] = applicationdata?.bannerImage?.slug || "";
    appData["programsSelected"] = [];
    appData["authors"] = [];
    appData["gradeLevels"] = [];
    appData["contentAreas"] = [];
    appData["tags"] = [];
    appData["frameworkDomains"] = [];
    appData["frameworkSubDomains"] = [];
    if (boolcondition === "course_path_continue") {
      appData["saveAs"] = "UNPUBLISHED";
    } else {
      appData["saveAs"] = "DRAFT";
    }

    applicationdata?.programsAvailable?.forEach((program, index) => {
      appData["programsSelected"][index] = program.slug;
    });
    applicationdata?.authors?.forEach((author, index) => {
      appData["authors"][index] = author.slug;
    });
    applicationdata?.gradeLevel?.forEach((level, index) => {
      appData["gradeLevels"][index] = level.slug;
    });
    applicationdata?.contentArea?.forEach((content, index) => {
      appData["contentAreas"][index] = content.slug;
    });
    applicationdata?.tags?.forEach((tag, index) => {
      appData["tags"][index] = tag.slug;
    });
    applicationdata?.frameworkDomain?.forEach((domain, index) => {
      appData["frameworkDomains"][index] = domain.slug;
    });
    applicationdata?.frameworkSubDomain?.forEach((subdomain, index) => {
      appData["frameworkSubDomains"][index] = subdomain.slug;
    });

    axios
      .post(`${URL}/create-course`, appData)
      .then(function (response) {
        //  //console.log("response ::: ", response);
        if (response.data) {
          dispatch(getDataBySlug(response.data.response.courseSlug));
          if (boolcondition === "course_path_continue") {
            dispatch({
              type: types.CREATE_COURSE_FORM_DATA,
              payload: { saveAs: "UNPUBLISHED" },
            });
          } else {
            dispatch({
              type: types.CREATE_COURSE_FORM_DATA,
              payload: { saveAs: "DRAFT" },
            });
          }
          dispatch({
            type: types.CREATE_COURSE_FORM_DATA,
            payload: { slug: response.data.response.courseSlug },
          });
          sessionStorage.setItem(
            "courseSlug",
            response.data.response.courseSlug
          );
          if (
            store &&
            store.getState().coursehome.application.saveAs === "UNPUBLISHED"
          ) {
            props.history.push(
              `/${response.data.response.courseSlug}/CoursePath`
            );
          }
        }
      })
      .catch(function (error) {
        if (
          error?.response?.data?.errors?.name?.[0] ===
          "The name has already been taken."
        ) {
          dispatch({ type: types.CHECK_COURSE_NAME, payload: true });
        }
        console.error("..error", error);
      });
    // let finalTime = performance.now();
    // if (finalTime - initialTime >= 600) {
    //   dispatch({ type: types.HIDE_LOADER });
    // } else {
    //   setTimeout(() => {
    //     dispatch({ type: types.HIDE_LOADER });
    //   }, 600 - (finalTime - initialTime));
    // }
    return Promise.resolve(true);
  };
};

export const UpdateCreateCoursePostData = (props, tobeUpdated, isPopupData) => {
  // //console.log("..props", props);
  return (dispatch) => {
    let initialTime = performance.now();
    dispatch({ type: types.SHOW_LOADER });

    dispatch({ type: types.CHECK_COURSE_NAME, payload: false });
    dispatch({ type: types.NAVIGATE_TO_COURSE_PATH_EDIT, payload: true });

    let appData = {};
    let applicationdata = store && store.getState().coursehome.application;
    //  //console.log("..apllicationData", applicationdata, applicationdata.saveAs);
    appData["name"] = applicationdata.courseName;
    appData["objective"] = applicationdata.courseObjective;
    appData["description"] = applicationdata.courseDescription;
    appData["url"] = applicationdata.url;
    appData["programsSelected"] = [];
    appData["authors"] = [];
    appData["gradeLevels"] = [];
    appData["contentAreas"] = [];
    appData["tags"] = [];
    appData["frameworkDomains"] = [];
    appData["frameworkSubDomains"] = [];
    if (tobeUpdated && tobeUpdated === "UNPUBLISHED") {
      appData["saveAs"] = "UNPUBLISHED";
    } else {
      appData["saveAs"] = applicationdata.saveAs;
    }
    appData["courseSlug"] = applicationdata.slug;
    appData["avatarSlug"] = applicationdata?.avatarImage?.slug || "";
    appData["bannerSlug"] = applicationdata?.bannerImage?.slug || "";
    applicationdata?.programsAvailable?.forEach((program, index) => {
      appData["programsSelected"][index] = program.slug;
    });
    applicationdata?.authors?.forEach((author, index) => {
      appData["authors"][index] = author.slug;
    });
    applicationdata?.gradeLevel?.forEach((level, index) => {
      appData["gradeLevels"][index] = level.slug;
    });
    applicationdata?.contentArea?.forEach((content, index) => {
      appData["contentAreas"][index] = content.slug;
    });
    applicationdata?.tags?.forEach((tag, index) => {
      appData["tags"][index] = tag.slug;
    });
    applicationdata?.frameworkDomain?.forEach((domain, index) => {
      appData["frameworkDomains"][index] = domain.slug;
    });
    applicationdata?.frameworkSubDomain?.forEach((subdomain, index) => {
      appData["frameworkSubDomains"][index] = subdomain.slug;
    });
    axios
      .put(`${URL}/update-course`, appData)
      .then(function (response) {
        if (response.data) {
          // dispatch(getDataBySlug(response.data.response.courseSlug));
          dispatch({
            type: types.CREATE_COURSE_FORM_DATA,
            payload: { slug: response.data.response.courseSlug },
          });
          sessionStorage.setItem(
            "courseSlug",
            response.data.response.courseSlug
          );
          if (tobeUpdated && tobeUpdated === "UNPUBLISHED") {
            dispatch({
              type: types.CREATE_COURSE_FORM_DATA,
              payload: { saveAs: "UNPUBLISHED" },
            });
          }
          if (appData.saveAs !== "DRAFT" && isPopupData !== true) {
            props.history.push(
              `/${response.data.response.courseSlug}/CoursePath`
            );
            //props.history.push("/coursePath");
          }
          dispatch({ type: types.CHECK_COURSE_NAME, payload: false });
          let finalTime = performance.now();
          if (finalTime - initialTime >= 600) {
            dispatch({ type: types.HIDE_LOADER });
          } else {
            setTimeout(() => {
              dispatch({ type: types.HIDE_LOADER });
            }, 600 - (finalTime - initialTime));
          }
        }
      })
      .catch(function (error) {
        if (
          error?.response?.data?.errors?.name?.[0] ===
          "The name has already been taken."
        ) {
          dispatch({ type: types.CHECK_COURSE_NAME, payload: true });
        }
        let finalTime = performance.now();
        if (finalTime - initialTime >= 600) {
          dispatch({ type: types.HIDE_LOADER });
        } else {
          setTimeout(() => {
            dispatch({ type: types.HIDE_LOADER });
          }, 600 - (finalTime - initialTime));
        }
        //alert("...checkcourse name");
        console.error("..error", error);
      });

    return Promise.resolve(true);
  };
};

export const goBackToCreateCourse = (slug, page, props) => {
  return (dispatch) => {
    // getDataBySlug(slug)
    if (page === "course_path") {
      props.history.push(`/${slug}/create-course`);
      //props.history.push("/create-course");
    }
  };
};
export const getDataBySlug = (
  slug,
  page,
  props,
  isPopUpData,
  applicationLeveldata
) => {
  return (dispatch) => {
    let initialTime;

    if (page !== "edit_catalogue") {
      initialTime = performance.now();
      dispatch({ type: types.SHOW_LOADER });
    }
    dispatch({ type: types.INITIAL_STATE, allFrameworkSubDomain: [] });
    axios
      .get(`${URL}/get-course/${slug}`)
      .then(function (response) {
        if (response.data) {
          let allDomainFrameworks =
            store && store.getState().coursehome.allFrameworks;
          let appData = {};
          let applicationdata = response.data;
          if (isPopUpData) {
            appData["slug"] = applicationLeveldata.slug;
            appData["courseName"] = applicationLeveldata.courseName;
            appData["createdBy"] = applicationdata?.createdBy || null;

            appData["courseObjective"] = [];
            appData["courseDescription"] =
            applicationLeveldata.courseDescription;
            appData["url"] = applicationLeveldata.url;
            appData["programsAvailable"] = [];
            applicationLeveldata?.courseObjective?.forEach(
              (objective, index) => {
                appData["courseObjective"].push(objective);
              }
            );
            if (
              applicationLeveldata.programsAvailable &&
              applicationLeveldata.programsAvailable.length > 0 &&
              applicationLeveldata.programsAvailable[0] !== null
            ) {
              applicationLeveldata?.programsAvailable?.forEach(
                (program, index) => {
                  let object = {};
                  object["name"] = program.name;
                  object["slug"] = program.slug;
                  appData["programsAvailable"].push(object);
                }
              );
            }
            appData["avatarImage"] = applicationLeveldata.avatarImage;
            appData["bannerImage"] = applicationLeveldata.bannerImage;
          } else {
            appData["slug"] = applicationdata.slug;
            appData["courseName"] = applicationdata.courseName;
            appData["createdBy"] = applicationdata.createdBy;
            appData["courseObjective"] = [];
            appData["courseDescription"] = applicationdata.courseDescription;
            appData["url"] = applicationdata.url;
            appData["programsAvailable"] = [];
            applicationdata?.courseObjective?.forEach((objective, index) => {
              appData["courseObjective"].push(objective);
            });
            if (
              applicationdata.programsAvailable &&
              applicationdata.programsAvailable.length > 0 &&
              applicationdata.programsAvailable[0] !== null
            ) {
              applicationdata?.programsAvailable?.forEach((program, index) => {
                let object = {};
                object["name"] = program.name;
                object["slug"] = program.slug;
                appData["programsAvailable"].push(object);
              });
            }
            appData["avatarImage"] = applicationdata.avatarImage;
            appData["bannerImage"] = applicationdata.bannerImage;
          }
          appData["authors"] = [];
          appData["gradeLevel"] = [];
          appData["contentArea"] = [];
          appData["tags"] = [];
          appData["frameworkDomain"] = [];
          appData["frameworkSubDomain"] = [];
          appData["saveAs"] = applicationdata.saveAs;
          appData["status"] = applicationdata.status;
          appData["courseId"] = applicationdata.courseDisplayId;
          if (
            applicationdata.authors &&
            applicationdata.authors.length > 0 &&
            applicationdata.authors[0] !== null
          ) {
            applicationdata?.authors?.forEach((author, index) => {
              let object = {};
              object["name"] = author.name;
              object["slug"] = author.slug;
              appData["authors"].push(object);
            });
          }
          if (
            applicationdata.gradeLevel &&
            applicationdata.gradeLevel.length > 0 &&
            applicationdata.gradeLevel[0] !== null
          ) {
            applicationdata?.gradeLevel?.forEach((level, index) => {
              let object = {};
              object["name"] = level.name;
              object["slug"] = level.slug;
              appData["gradeLevel"].push(object);
            });
          }
          if (
            applicationdata.contentArea &&
            applicationdata.contentArea.length > 0 &&
            applicationdata.contentArea[0] !== null
          ) {
            applicationdata?.contentArea?.forEach((content, index) => {
              let object = {};
              object["name"] = content.name;
              object["slug"] = content.slug;
              appData["contentArea"].push(object);
            });
          }
          if (
            applicationdata.tags &&
            applicationdata.tags.length > 0 &&
            applicationdata.tags[0] !== null
          ) {
            applicationdata?.tags?.forEach((tag, index) => {
              let object = {};
              object["name"] = tag.name;
              object["slug"] = tag.slug;
              appData["tags"].push(object);
            });
          }
          if (
            applicationdata.frameworkDomain &&
            applicationdata.frameworkDomain.length > 0 &&
            applicationdata.frameworkDomain[0] !== null
          ) {
            applicationdata?.frameworkDomain?.forEach((domain, index) => {
              let object = {};
              object["name"] = domain.name;
              object["slug"] = domain.slug;
              appData["frameworkDomain"].push(object);

              let exisitingDomains =
                store && store.getState().coursehome.allFrameworkSubDomain;
              if (
                exisitingDomains.findIndex(
                  (domaine) => domaine.slug === domain.slug
                ) === -1
              ) {
                let DomainIndex = allDomainFrameworks.findIndex(
                  (item) => item.slug === domain.slug
                );
                if (DomainIndex !== -1) {
                  allDomainFrameworks[DomainIndex]?.subDomains?.forEach(
                    (subdomain) => {
                      dispatch({
                        type: types.ADD_DATA_TO_SUBDOMAIN,
                        payload: {
                          name: subdomain.name,
                          slug: subdomain.slug,
                          // index: index,
                          index:
                            domain.name.indexOf(":") !== -1 &&
                            domain.name[domain.name.indexOf(":") - 1],
                        },
                      });
                    }
                  );
                }
              }
            });
          }
          if (
            applicationdata.frameworkSubDomain &&
            applicationdata.frameworkSubDomain.length > 0 &&
            applicationdata.frameworkSubDomain[0] !== null
          ) {
            applicationdata?.frameworkSubDomain?.forEach((subdomain, index) => {
              let object = {};
              object["name"] = subdomain.name;
              object["slug"] = subdomain.slug;
              appData["frameworkSubDomain"].push(object);
            });
          }
          ////console.log("...app data get data by slug", appData);
          dispatch({ type: types.GET_DATA_BY_SLUG, payload: appData });
          if (page === "edit_catalogue") {
            //props.history.push("/create-course");
            props.history.push(`/${applicationdata.slug}/create-course`);
          }
        }

        let finalTime = performance.now();
        if (finalTime - initialTime >= 1000) {
          dispatch({ type: types.HIDE_LOADER });
        } else {
          setTimeout(() => {
            dispatch({ type: types.HIDE_LOADER });
          }, 1000 - (finalTime - initialTime));
        }
      })

      .catch(function (error) {
        dispatch({ type: types.HIDE_LOADER });
        console.error("..error", error);
      });
  };
};

export const getallDraftData = () => {
  return (dispatch) => {
    axios
      .get(`${URL}/get-course`)
      .then(function (response) {
        if (response.data) {
          dispatch({
            type: types.GET_DRAFT_DATA,
            payload: response.data,
          });
        }
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};

export const getCoursesForAuthor = () => {
  return (dispatch) => {
    axios
      .get(`${URL}/get-content-author-courses`)
      .then(function (response) {
        if (response.data) {
          dispatch({
            type: types.GET_DRAFT_DATA,
            payload: response?.data?.response,
          });
        }
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};

export const checkCourseName = (payload) => {
  return (dispatch) => {
    dispatch({ type: types.CHECK_COURSE_NAME, payload: payload });
  };
};

export const showLoader = () => {
  return (dispatch) => {
    dispatch({ type: types.SHOW_LOADER });
  };
};

export const hideLoader = () => {
  return (dispatch) => {
    dispatch({ type: types.HIDE_LOADER });
  };
};
