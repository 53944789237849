import React, { useEffect, useState, useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { store } from "../../App";
import { types } from "../../reducers/types";
import parse from "html-react-parser";
import ContextUser from "./../../contexts/user-context";

export const TKLModal = ({
  showDialog,
  message,
  msgType,
  isCohortsPage,
  redirect,
  isReload,
  isRegisterPage,
  setFlag,
  primaryBtnColor = "",
}) => {
  const [show, setShow] = useState(showDialog);
  const { history } = useContext(ContextUser);

  const handleClose = () => {
    if (isCohortsPage) {
      redirect.push("/manageCohorts");
    }
    if (store && store.getState().coursepath?.isReload) {
      history.go(0);
    }
    if (store && store.getState()?.coursepath?.redirectToPath) {
      if (store.getState().coursepath?.redirectToPath === "closeWindow") {
        window?.close();
      } else {
        history.push(`${store && store.getState().coursepath?.redirectToPath}`);
        history.go(0);
      }
    }
    store.dispatch({
      type: types.API_SUCCESS,
      payload: "",
      pathName: "",
    });

    store.dispatch({
      type: types.API_ERROR,
      payload: "",
      isReload: false,
    });
    setShow(false);
    if (setFlag) {
      setFlag(true);
    }
  };
  const handleShow = () => {
    setShow(true);
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Alert</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {msgType === "warning" && <span className="material-icons">warning</span>}{" "}
          {isRegisterPage ? parse(message) : message}
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={
              primaryBtnColor
                ? {
                    backgroundColor: primaryBtnColor,
                    color: "white",
                  }
                : {}
            }
            className="btn btn-secondary rounded-pill pl-4 pr-4"
            onClick={handleClose}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TKLModal));
